import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Row from "../components/row"
import BackButton  from "../components/backButton"
import text from "../data/nl";
import NewsBlock from "../components/newsBlock"

import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title={data.news.title} description={data.news.excerpt}/>
    <Row size="small" classes="pt-200" image={data.news.background} id="NewsItem">
      <article>
        <div className="column col-12">
          <h1 className="uppercase t-50 bold">{data.news.title}</h1>
          <div className="bold mb-20">{data.news.date}</div>
        </div>
        <div className="news-column mb-30">
          <div className="detail-page" dangerouslySetInnerHTML={{ __html: data.news.content }} />
          <BackButton>{text.news.back}</BackButton>
        </div>
        <div className="column news-column-image">
          <img src={data.news.thumbnail} className="mt-8" alt={data.news.title}/>
        </div>
      </article>
    </Row>
    <Row size="small" id="NewsItemReadMore">
      <h3 className={"column col-12 mt-30 t-30  bold uppercase " + ( data.relatedNews.edges.length !== 0 ? "" : "hidden" )}>{text.news.relatedArticles}</h3>
      <ul className="hbox space-between list-style-none">
        {data.relatedNews.edges.map((article) =>
          <li className="column col-4 mt-40" key={article.node.title}>
            <NewsBlock article={article.node}/>
          </li>
        )}
      </ul>
    </Row>
  </Layout>
)
export const postQuery = graphql`
  query BlogPostByPath($path: String!, $tag: String) {
    news(path: { eq: $path }) {
      title
      thumbnail
      background
      content
      path
      date(formatString: "DD-MM-YYYY")
      tags
    }
    relatedNews: allNews (limit: 3, filter: {tags: { in: [$tag] }, path: { ne: $path } } ) {
      edges {
        node {
          title
          thumbnail
          excerpt
          path
          date(formatString: "DD-MM-YYYY")
          tags
        }
      }
    }  
  }
`
